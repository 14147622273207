.load-view {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #009de05e;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;

  img {
    width: 100px;
    height: 100px;
    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
    transform-origin: calc(50% + -10px) calc(50% + -10px);
  }

  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
}