#sendTransaction {
    display: flex;
    flex-direction: column;
    margin: 1.5rem 0;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.8rem;
        margin-bottom: 45px;
    
        .backTo {
            font-size: 1rem;
            color: #b1b1b1;
            cursor: pointer;
    
            span {
                color: #4b88bb;
            }
        }
    }
    
    .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 2rem 0;
    
        &-image {
            width: 100%;
            max-width: 3.5rem;
        }
    
        &-name {
            margin-left: 1.5rem;
            color: #0171ae;
            text-transform: uppercase;
        }
    }
    
    .blue-bar {
        border: 1px solid #03a1d4;
        width: 100%;
        align-self: center;
    }

    .cardLogos {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        .formLogos {
            position: absolute;
            right: 15px;
            
            .visa {
                width: 40px;
            }
    
            .master {
                width: 40px;
            }
        }
    }

    .MuiFormControl-root {
        margin: 1rem 0;
    }

    .MuiInputBase-input {
        color: #0171ae;
        // margin-left: 15px;
        font-size: 1.3rem;
    }

    .MuiFormLabel-root {
        font-family: inherit;
        // margin-left: 15px;
        color: #b1b1b1;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: 2px solid #b1b1b1;
    }

    // .MuiInput-underline:after {
    //     border-bottom: 2px solid #22c1ff;
    // }

    // label + .MuiInput-formControl {
    //     margin-top: 30px;
    // }

    .fields {
        display: flex;
        justify-content: space-between;
        & > div {
            width: 50%;
        }
    }
    
}