.selector {

    &:hover {
        background-color: #F5FDFF;
    }

    border: 1px solid #42c6ff;
    border-radius: 3px;
    width: 100%;
    max-width: 350px;
    text-align: center;
    cursor: pointer;

    &-logo {
        margin: 50px 0;
        img{
            width: auto;
            height: 90px;
        }
    }

    &-title{
        font-weight: 400;
        font-size: 1.5rem;
        color:  #4b88bb;
        text-transform: uppercase;
    }

    hr {
        border: 1px solid #dedcdc;
        margin: 25px 10px;
    }

    &-description {
        text-align: center;
        font-size: 1rem;
        margin: 0 25px;
        color: #b1b1b1;
        min-height: 120px;
        @media (max-width: 600px) {
            min-height: 0px;
        }
    }

    &-bank {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 35px 30px;

        .logo {
            border-radius: 10px;
            width: 100%;
            max-width: 55px;
            margin: 10px;
        }
    }

    .plus-text {
        font-size: 13px;
        color: #4b88bb;
    }
}
