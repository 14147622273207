.title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: .5rem 0 2rem;

    &-image {
        width: 100%;
        width: 3.5rem;
    }

    &-name {
        margin-left: 1.5rem;
        text-transform: uppercase;
    }
}

.green {
    border: 1px solid #d7ffc6;
    width: 100%;
    align-self: center;
}

.payment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #bebebe;
    padding: 50px 0;
    &-logo {
        width: 45px;
    }

    &-description {
        color: #73848f;
        font-size: 16px;
        font-weight: 500;
    }

    &-modify {
        font-size: 10px;
        color: #03a1d4;
        text-transform: uppercase;
        cursor: pointer;
    }
}
