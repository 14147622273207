.checkoutPaymentSpei {
  max-width: 600px;
  width: 100%;

  .text-center {
    text-align: center;
  }

  button {
    width: 100%;
    max-width: 570px;
  }

  .print-container {
    margin: 1rem;
    display: block;
    page-break-before: auto;

    .container-title-reference {
      display: flex;
      align-items: center;
      padding: 0px 40px 5px 40px;
      margin-bottom: 15px;
      text-transform: uppercase;
      color: #4b88bb;
      font-weight: 500;
      border-bottom: solid #b0ff96 1px;

      .icon-checkout {
        height: 50px;
        margin-right: 15px;
      }
    }

    .container-referencePay {
      border: solid 1px rgb(218, 220, 220);
      padding: 20px;

      .logo {
        width: 100%;
        margin-bottom: 10px;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img {
          width: 140px;
        }
        span {
          margin-right: 10px;
          color: #b9b9b9;
          font-size: 13px;
        }
      }

      .table-data {
        display: grid;
        grid-template-columns: 140px auto;
        column-gap: 10px;
        row-gap: 10px;
        width: 100%;
        color: #808080;
        padding: 0 40px 15px 40px;
        margin-bottom: 10px;
        border-bottom: solid #b0ff96 1px;

        @media (max-width: 600px) {
          & {
            grid-template-columns: 100px auto;
            padding: 0 0px 15px 0px;
            column-gap: 7px;
          }
        }

        .line {
          grid-column: 1 / -1;
          height: 1px;
          background: #ececec;
        }

        .title {
          display: block;
          margin: 0;
          padding-left: 10px;
          justify-content: start;
        }

        .data-reference {
          font-weight: 500;
          color: #255782;
          padding-right: 10px;
        }

        .monto-weight {
          font-weight: 600;
          color: #255782;
        }
      }

      .services-allow {
        margin-top: 15px;
        text-align: center;
        color: #808080;
        padding-bottom: 15px;
        border-bottom: solid #b0ff96 1px;
        .services-images {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .logo {
            width: auto;
            height: 50px;
            margin: 10px 5px;
            max-width: 90px;
          }
        }
      }

      .barcode {
        display: flex;
        justify-content: center;
      }

      .purchase-details {
        margin-top: 10px;
        text-align: center;
        color: #808080;
        padding-bottom: 10px;
        border-bottom: solid #b0ff96 1px;
        .items-list {
          display: grid;
          grid-template-columns: calc(50% - 5px) calc(50% - 5px);
          column-gap: 10px;
          row-gap: 15px;
          flex-wrap: wrap;
          .logo {
            width: 90px;
            height: 50px;
            margin: 10px 5px;
          }
          .quantity {
            font-size: 11px;
          }
        }
      }  

      .instructions-details {
        .instructions-steps {
          color: black;
          text-align: left;
          padding: 0 30px;
        }
      }  
    }
  }
}

@media print {
  .print-container {
    margin: 1rem;
    display: block;
    page-break-before: auto;

    .container-title-reference {
      display: flex;
      align-items: center;
      padding: 0px 40px 5px 40px;
      margin-bottom: 15px;
      text-transform: uppercase;
      color: #4b88bb;
      font-weight: 500;
      border-bottom: solid #b0ff96 1px;

      .icon-checkout {
        height: 50px;
        margin-right: 15px;
      }
    }

    .container-referencePay {
      border: solid 1px rgb(218, 220, 220);
      padding: 20px;

      .logo {
        width: 100%;
        margin-bottom: 0px;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img {
          width: 140px;
        }
        span {
          margin-right: 10px;
          color: #b9b9b9;
          font-size: 13px;
        }
      }

      .table-data {
        display: grid;
        grid-template-columns: 140px auto;
        column-gap: 10px;
        row-gap: 10px;
        width: 100%;
        color: #808080;
        padding: 0 40px 15px 40px;
        margin-bottom: 15px;
        border-bottom: solid #b0ff96 1px;

        @media (max-width: 600px) {
          & {
            grid-template-columns: 100px auto;
            padding: 0 0px 15px 0px;
            column-gap: 7px;
          }
        }

        .line {
          grid-column: 1 / -1;
          height: 1px;
          background: #ececec;
        }

        .title {
          display: block;
          margin: 0;
          padding-left: 10px;
        }

        .data-reference {
          font-weight: 500;
          color: #255782;
          padding-right: 10px;
        }

        .monto-weight {
          font-weight: 600;
          color: #255782;
        }
      }

      .barcode {
        display: flex;
        justify-content: center;
        margin-top: 15px;
      }

      .services-allow {
        margin-top: 15px;
        text-align: center;
        color: #808080;
        padding-bottom: 15px;
        border-bottom: solid #b0ff96 1px;
        .services-images {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .logo {
            width: auto;
            height: 50px;
            margin: 10px 5px;
            max-width: 90px;
          }
        }
      }

      .purchase-details {
        margin-top: 10px;
        text-align: center;
        color: #808080;
        padding-bottom: 10px;
        border-bottom: solid #b0ff96 1px;
        .items-list {
          display: grid;
          grid-template-columns: calc(25% - 5px) calc(25% - 5px) calc(25% - 5px) calc(25% - 5px);
          column-gap: 10px;
          row-gap: 15px;
          flex-wrap: wrap;
          .logo {
            width: 90px;
            height: 50px;
            margin: 10px 5px;
          }
          .quantity {
            font-size: 11px;
          }
        }
      }

      .instructions-details {
        .instructions-steps {
          color: black;
          text-align: left;
          padding: 0 30px;
        }
      }
    }
  }
}

.copyAlert {
  & > div {
    background-color: #255782;
    color: white;
    justify-content: center;
  }
}