.smallContainer {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border: 1px solid #42c6ff;
    border-radius: 3px;
    width: 100%;
    text-align: center;
    max-height: 250px;
}
