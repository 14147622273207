.CheckoutWithOuth3dSecure {
  display: flex;
  flex-wrap: nowrap;
  gap: 50px;

  @media (max-width: 800px) {
    & {
      flex-wrap: wrap;
    }
  }

  .container-info-sale {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: 600px) {
      margin-top: 20px;
    }
  }
  
  .modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .container-modal-info {
      // background-color: white;
      padding: 0px;
      // border-radius: 5px;
    }
  }
}