.process-By {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 0.8rem;

    div{
        img {
            max-width: 130px;
            margin-left: 10px;
            height: 50px;
        }
    }
}

.title {
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 1rem 0;

    &-image {
        width: 100%;
        max-width: 3.5rem;
    }

    &-name {
        margin-left: 10px;
        font-weight: 400;
        font-size: 1.3rem;
    }
}

.blue {
    border: 1px solid #03a1d4;
    width: 100%;
    align-self: center;
}

.green {
    border: 1px solid #d7ffc6;
    width: 100%;
    align-self: center;
}

.list-container {
    margin: 2.5rem 0;
    max-height: 150px;
    height: 300px;
    overflow: scroll;
}

.total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;
    font-weight: 500;
    color: #0171ae;
}
