.checkoutMain {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-evenly;
    margin: auto;
    min-height: calc(100vh - 236px);
    align-items: center;

    .container {
        width: 100%;
        &-title {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            
            img  {
                max-width: 75px;
                width: 100%;
            }
    
            h1 {
                font-weight: 400;
                margin-left: 25px;
                font-size: 1.5rem;
                color:  #0171ae;
                text-transform: uppercase;
                span {
                    font-weight: 600;
                }
    
                @media (max-width: 600px) {
                    margin-left: 0;
                }
            }
        }
    
        .container-products {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            margin: 2rem auto;
            gap: 50px;
        }
    }
    
    .butons {
        display: flex;
        justify-content: space-evenly;
        margin: 2rem 0;
    
        .MuiButton-contained {
            box-shadow: none;
            // background-color: #22c1ff;
        }
    
        .large {
            width: 100% !important;
        }
    
        .MuiButton-root {
            color: white;
            font-size: 1rem;
            border-radius: 5px;
    
            &:hover {
                box-shadow: none;
                // background-color: #0171ae;
            }
        }
    }
    
    .header-commerce {
        width: 100%;
        text-align: center;
        color: white;
        padding: 16px;
        font-size: 2rem;
        font-weight: 600;
        margin: 0 0 20px 0;
    
        img {
            height: 70px;
        }
    }
}