.item {
   display: flex;
   align-items: center;
   justify-content: space-between;
   border-bottom: 1px solid #b1b1b1;
   margin-bottom: 1rem;
   padding-bottom: 1rem;
   color: #b1b1b1;

   &-name {
       font-size: .9rem;
       text-transform: uppercase;
       
   }

   &-info {
    font-size: .8rem;
   }

   &-currency {
    h2 {
        font-size: 1.7rem;
        font-weight: 400;
        color: #0171ae;
    }
   }
}
