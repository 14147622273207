#root {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.timer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  .x {
    width: 70%;
  }
  .timer {
    display: flex;
    margin: 0 auto;
    margin-right: 50px;
    max-width: 300px;
    font-weight: 500;
  }
}

nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 15px 0;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  .nav {
    &-link {
      font-size: 1rem;
      display: flex;
      .backTo {
        cursor: pointer;
        color: #4b88bb;
        margin-right: 40px;
        display: flex;
        align-items: center;
      }
      a {color: #b1b1b1;}
    }

    &-logo {
      display: flex;
      align-items: center;

      @media (max-width: 600px) {
        margin-top: 20px;
      }

      .language {
        display: flex;
        font-size: 1rem;

        p {
          cursor: pointer;
        }
        
        p:nth-child(2) {
          border-left: 1px solid black;
          margin-left: 15px;
          padding-left: 15px;
        }
      }

      .logo {
        max-width: 200px;
        margin-left: 20px;
        height: 50px;
      }
    }
  }

  .service-selected {
    display: flex;
    align-items: center;
    color: #4b88bb;
    text-transform: uppercase;
    font-weight: 500;
    img {
      height: 50px;
      margin-right: 20px;
    }
  }
}

a {
  text-decoration: none;
  color: #4b88bb;
}

// main {
//   flex: 1;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
// }

.container-footer {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-top: 20px;
}

footer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 100%;
  
  flex-shrink: 0;

  .brands {
    width: 100%;
    max-width: 830px;
    text-align: center;
    align-self: center;
  }
  
  .terms {
    text-align: center;
    margin: 10px 0;
    font-size: 0.8rem;
    color: #b1b1b1;
  }

  .container-brands {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    .container-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 25px;
      & > img {
        width: 90px;
        height: 50px;
      }
    }
    .securreTrust {
      margin-top: 10px;
    }
  }
}

$sizes: 12;

@for $i from 0 through $sizes {
  $padding: $i * 0.50rem;
  /* padding #{$padding} */
  .p-#{$i} {padding: $padding !important;}
  .pl-#{$i} {padding-left: $padding !important;}
  .pr-#{$i} {padding-right: $padding !important;}
  .pt-#{$i} {padding-top: $padding !important;}
  .pb-#{$i} {padding-bottom: $padding !important;}
  .px-#{$i} {padding-left: $padding !important; padding-right: $padding !important;}
  .py-#{$i} {padding-top: $padding !important; padding-bottom: $padding !important;}
}

@for $i from 0 through $sizes {
  $margin: $i * 0.50rem;
  /* margin #{$margin} */
  .m-#{$i}  {margin: $margin !important;}
  .ml-#{$i} {margin-left: $margin !important;}
  .mr-#{$i} {margin-right: $margin !important;}
  .mt-#{$i} {margin-top: $margin !important;}
  .mb-#{$i} {margin-bottom: $margin !important;}
  .mx-#{$i} {margin-left: $margin !important; margin-right: $margin !important;}
  .my-#{$i} {margin-top: $margin !important; margin-bottom: $margin !important;}
}
