.checkout-3d-v2 {
  .Checkout3dSecurev2 {
    display: flex;
    flex-wrap: nowrap;
    gap: 50px;

    @media (max-width: 800px) {
      & {
        flex-wrap: wrap;
      }
    }
  }

  .container-info-cardholder {
    width: 100%;
  }

  .container-info-sale {
    width: 100%;
  }
}